import React, { Component, Suspense } from 'react';
import { Grid } from "react-bootstrap";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import { submitProfileRequest, submitLogoutRequest } from "../redux/actions";

import Loading from '../components/Loading'
import Header from './Header'
import Footer from './Footer'

import { axiosInstance } from '../redux/axiosConfig';

import './navWrapper.scss'

const NavWrapper = WrappedComponent => {
  return class extends Component {
    componentDidMount() {
      if (!this.props.userStore.loaded) {
        axiosInstance.get("/api/profile")
          .then(response => {
            this.props.submitProfileRequest(response)
          })
          .catch(e => {
            this.props.submitProfileRequest({})
            console.dir(e)
          })
      }
    }

    render() {
      if (!this.props.userStore.loaded) {
        return <Suspense fallback={<Loading loading={true}/>} delay={500} />
      } else {
        return <div>
          <Header />

          <Grid fluid={true}>
            <div className="main-content">
              {body(WrappedComponent, this.props)}
            </div>
          </Grid>

          <Footer />
        </div>
      }
    }
  }
}

const body = (WrappedComponent, props) => {
  return <Suspense fallback={<Loading loading={true}/>} >
    <WrappedComponent {...props} />
  </Suspense>
}

const mapStateToProps = state => {
  const userStore = state.user;
  return { userStore } ;
}

export default compose(
  connect(
    mapStateToProps,
    { submitProfileRequest, submitLogoutRequest }
  ),
  withRouter,
  NavWrapper
);
