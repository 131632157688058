import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import image from './navbar/clb.png';

class App extends Component {
  render() {
    const url = window.location.href;
    return <div>
      <Helmet>
        {/* General tags */}
        <title>{title}</title>
        <meta name="description" content={description}/>
        <meta name="image" content={image} />
        <meta name="keywords" content="cloud, leaderboard" />

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        {/* <meta property="fb:app_id" content={seo.social.fbAppID} /> */}

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        {/* <meta name="twitter:creator" content={seo.social.twitter} /> */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>

      {this.props.children}
    </div>
  }
}

const title = `Build leaderboards easily - CloudLeaderboard`
const description = `CloudLeaderboard provides an easy way to add a leaderboard to your project. We provide a simple and documented JSON API that lets you get started quickly without worrying about maintaining infrastructure`

export default App;
