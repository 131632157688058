import {
  SET_INVOICES,
} from "../actionTypes"

const initialState = {
  invoices: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_INVOICES:
    let newInvoices = {}
    action.payload.forEach(i => {
      newInvoices[i.id] = { ...i }
    });
    return {
      ...state,
      invoices: {
        ...state.invoices,
        ...newInvoices,
      }
    }
    default:
      return { ...state };
  }
}
