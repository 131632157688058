import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import './Loading.css'

export default ({ loading, component }) => {
  if (loading) {
    return <div className='loading-spinner'>
      <ClipLoader
        // css={override}
        sizeUnit={"px"}
        size={150}
        color={'#123abc'}
        loading={loading}
      />
    </div>
  }

  return component
}
