import {
  GET_PLANS,
  SET_PLAN
} from "../actionTypes"

const initialState = {
  plans: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PLANS:
      let newPlans = {}
      action.payload.forEach(plan => {
        newPlans[plan.id] = { ...plan }
      });
      return {
        ...state,
        plans: {
          ...state.plans,
          ...newPlans,
        }
      }
    case SET_PLAN:
      return {
        ...state,
        plans: {
          ...state.plans,
          [action.payload.id]: action.payload
         }
      }
    default:
      return {
        ...state,
        plans: { ...state.plans }
      };
  }
}
