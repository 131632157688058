import {
  GET_PAYMENT_PLANS
} from "../actionTypes"

const initialState = {
  plans: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENT_PLANS:
      let newPlans = {}
      action.payload.forEach(plan => {
        newPlans[plan.id] = { ...plan }
      });
      return {
        ...state,
        plans: {
          ...state.plans,
          ...newPlans
        }
      }
    default:
      return {
        ...state,
        plans: { ...state.plans }
      };
  }
}
