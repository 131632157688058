import { combineReducers } from "redux";
import user from "./user";
import billingPlans from "./billingPlans";
import plans from "./plans";
import stripePlans from "./stripePlans";
import users from "./users";
import thing from "./thing";
import onboarding from "./onboarding";
import boards from "./boards";
import topScores from "./topScores";
import invoices from "./invoices";
import subscription from "./subscription";
import creds from "./creds";

export default combineReducers({
  user,
  billingPlans,
  plans,
  stripePlans,
  users,
  thing,
  onboarding,
  boards,
  topScores,
  invoices,
  subscription,
  creds
});
