import {
  SET_STRIPE_PLANS
} from "../actionTypes"

const initialState = {
  plans: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_STRIPE_PLANS:
      let newPlans = {}
      action.payload.forEach(plan => {
        newPlans[plan.id] = { ...plan }
      });
      return {
        ...state,
        plans: {
          ...state.plans,
          ...newPlans,
        }
      }
    default:
      return {
        ...state,
        plans: { ...state.plans }
      };
  }
}
