import {
  SET_USER,
  LOGOUT_REQUEST,
  PROFILE_REQUEST
} from "../actionTypes"

const initialState = {
  user: {},
  loaded: false,
  authenticated: false
};

export default function(state = initialState, action) {
  const admin = action.payload &&
    action.payload.data &&
    action.payload.data.roles &&
    action.payload.data.roles.includes('admin')
  const authenticated = action.payload &&
    action.payload.data &&
    !!action.payload.data.id;

  switch (action.type) {
    case PROFILE_REQUEST:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.data,
        },
        loaded: true,
        admin: admin,
        authenticated: authenticated
      }
    case SET_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.data,
        },
        loaded: true,
        admin: admin,
        authenticated: true
      }
    case LOGOUT_REQUEST:
      return {
        user: {},
        loaded: true,
        authenticated: false
      }
    default:
      return {
        ...state,
        user: { ...state.user }
      };
  }
}
