import {
  SET_SUBSCRIPTION,
} from "../actionTypes"

const initialState = {
  subscription: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SUBSCRIPTION:
    return {
      ...state,
      subscription: {
        ...action.payload,
      }
    }
    default:
      return { ...state };
  }
}
