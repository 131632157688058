import {
  GET_USERS
} from "../actionTypes"

const initialState = {
  users: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      let newUsers = {}
      action.payload.data.forEach(user => {
        newUsers[user.id] = { ...user }
      });
      return {
        ...state,
        users: {
          ...state.users,
          ...newUsers
        }
      }
    default:
      return {
        ...state,
        user: { ...state.user }
      };
  }
}
