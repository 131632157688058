import React from 'react';
import {
  Navbar,
  Nav,
  MenuItem,
  NavItem,
  NavDropdown,
 } from "react-bootstrap";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';

import { submitProfileRequest, submitLogoutRequest } from "../redux/actions";

import clb from './clb.png';

import { axiosInstance } from '../redux/axiosConfig';

const Header = ({ userStore, history, submitLogoutRequest }) => {
  return <Navbar className="lb-navbar navbar-static-top" expand="lg" fluid={true}>
    <Navbar.Header>
      <Navbar.Brand href="/" className="lb-navbar-brand">
        <Link to={`/`}>
          <img alt="brand-icon" src={clb} height={35} className="h-12 mr-2 header-logo"/>
          <span>
            <span>Cloud</span><span className="color-purple500" >Leaderboard</span>
          </span>
        </Link>
      </Navbar.Brand>

      <Navbar.Toggle />
    </Navbar.Header>

    <Navbar.Collapse>
      {settingsOrLogin(userStore, history, submitLogoutRequest)}
    </ Navbar.Collapse>
  </Navbar>
}

const logoutRequest = (history, submitLogoutRequest) => {
  axiosInstance.post('/api/logout')
    .then(re => {
      submitLogoutRequest(re)
      history.push("/")
    })
    .catch(e => console.log(e))
}

const settingsOrLogin = (userStore, history, submitLogoutRequest) => {
  return userStore.authenticated ? (
    <Nav pullRight={true}>
      {navUserContent(userStore)}

      <NavDropdown title={userStore.user.email} id="basic-nav-dropdown">
        <MenuItem componentClass={Link} href="/billing" to="/billing">Billing</MenuItem>
        <MenuItem componentClass={Link} href="/settings" to="/settings">Settings</MenuItem>
        <MenuItem divider />
        <MenuItem id="signout" onClick={() => logoutRequest(history, submitLogoutRequest)}>Sign Out</MenuItem>
      </NavDropdown>
    </Nav>
    ) : (
      <Nav pullRight={true}>
        <NavItem id="getting-started" componentClass={Link} href="/guides/getting-started" to="/guides/getting-started" variant="outline-success">Getting Started</NavItem>
        <NavItem id="demo" componentClass={Link}
          onClick={() => {
            let win = window.open("https://leaderboard-best-language.s3.amazonaws.com/index.html", '_blank')
            win.focus()
          }}
          to=""
          variant="outline-success">Demo</NavItem>
        <NavItem id="demo" componentClass={Link}
          onClick={() => {
            const el = document.getElementById("pricing")
            el.scrollIntoView({ behavior: "smooth", block: 'start'});
          }}
          to=""
          variant="outline-success">Pricing</NavItem>
        <NavItem key={1} className="" componentClass={Link} href="/docs" to="/docs">
          <span className="nav-icon glyphicon glyphicon-book" aria-hidden="true"></span>
          Docs
        </NavItem>
        <NavItem id="signup" componentClass={Link} href="/signup" to="/signup" variant="outline-success">Sign Up</NavItem>
        <NavItem id="login" componentClass={Link} href="/login" to="/login" variant="success">Log In</NavItem>
      </Nav>
    )
}

const navUserContent = userStore => {
  return userStore.admin ? [
    <NavItem key={1} className="" componentClass={Link} href="/docs" to="/docs">Docs</NavItem>,
    <NavItem key={2} className="" componentClass={Link} href="/users" to="/users">Users</NavItem>,
    <NavItem key={3} className="" componentClass={Link} href="/plans" to="/plans">Plans</NavItem>,
    <NavItem key={4} className="" componentClass={Link} href="/" to="/">Boards (n/a)</NavItem>
  ] : [
      <NavItem key={1} className="" componentClass={Link} href="/docs" to="/docs">
        <span className="nav-icon glyphicon glyphicon-book" aria-hidden="true"></span>
        Docs
      </NavItem>,
      <NavItem key={2} className="" componentClass={Link} href="/boards" to="/boards">
        <span className="nav-icon glyphicon glyphicon-signal" aria-hidden="true"></span>
        Boards
      </NavItem>
  ]
}

const mapStateToProps = state => {
  const userStore = state.user;
  return { userStore } ;
}

export default connect(
    mapStateToProps,
    { submitProfileRequest, submitLogoutRequest }
  )(withRouter(Header))
