import {
  ADD_BOARDS,
  UPDATE_BOARD
} from "../actionTypes"

const initialState = {
  boards: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_BOARDS:
      let newBoards = {}
      action.payload.forEach(board => {
        newBoards[board.id] = { ...board }
      });
      return {
        ...state,
        boards: {
          // ...state.boards,
          ...newBoards,
        }
      }
    case UPDATE_BOARD:
      return {
        ...state,
        boards: {
          ...state.boards,
          [action.payload.id]: action.payload
         }
      }
    default:
      return {
        ...state,
        boards: { ...state.boards }
      };
  }
}
