import {
  SET_USER,
  LOGOUT_REQUEST,
  PROFILE_REQUEST,
  GET_PAYMENT_PLANS,
  SET_STRIPE_PLANS,
  GET_USERS,
  GET_PLANS,
  SET_PLAN,
  INC_THING,
  SET_ONBOARDING_PLAN,
  SIGNUP_COMPLETE,
  ADD_BOARDS,
  UPDATE_BOARD,
  SET_TOP_SCORES,
  SET_INVOICES,
  SET_SUBSCRIPTION,
  SET_CREDS
} from "./actionTypes"

export const submitLoginRequest = (response) => dispatch => {
  dispatch({
    type: SET_USER,
    payload: response
  })
};

const signupRequest = data => ({
  type: SET_USER,
  payload: data
});

export const submitSignupRequest = (response) => dispatch => {
  dispatch(signupRequest(response));
};


const logoutRequest = data => ({
  type: LOGOUT_REQUEST,
  payload: data
});

export const submitLogoutRequest = (response) => dispatch => {
  dispatch(logoutRequest(response));
};

export const submitProfileRequest = (response) => dispatch => {
  dispatch({
    type: PROFILE_REQUEST,
    payload: response
  })
};

export const submitPaymentPlansRequest = (response) => dispatch => {
  dispatch({
    type: GET_PAYMENT_PLANS,
    payload: response
  })
};

export const setStripePlans = (response) => dispatch => {
  dispatch({
    type: SET_STRIPE_PLANS,
    payload: response
  })
};

export const submitGetPlansRequest = response => dispatch => {
  dispatch({
    type: GET_PLANS,
    payload: response
  })
};

export const submitSetPlanRequest = response => dispatch => {
  dispatch({
    type: SET_PLAN,
    payload: response
  })
};

export const submitGetUsers = (response) => dispatch => {
  dispatch({
    type: GET_USERS,
    payload: response
  })
};

export const incThing = response => dispatch => {
  dispatch({
    type: INC_THING,
    payload: response
  })
}

export const setOnboardingPlan = response => dispatch => {
  dispatch({
    type: SET_ONBOARDING_PLAN,
    payload: response
  })
}

export const signupComplete = response => dispatch => {
  dispatch({
    type: SIGNUP_COMPLETE,
    payload: response
  })
}

export const addBoards = (response) => dispatch => {
  dispatch({
    type: ADD_BOARDS,
    payload: response
  })
};

export const updateBoard = (response) => dispatch => {
  dispatch({
    type: UPDATE_BOARD,
    payload: response
  })
};

export const setTopScores = (response) => dispatch => {
  dispatch({
    type: SET_TOP_SCORES,
    payload: response
  })
};

export const setInvoices = (response) => dispatch => {
  dispatch({
    type: SET_INVOICES,
    payload: response
  })
};

export const setSubscription = (response) => dispatch => {
  dispatch({
    type: SET_SUBSCRIPTION,
    payload: response
  })
};

export const setCreds = (response) => dispatch => {
  dispatch({
    type: SET_CREDS,
    payload: response
  })
};
