import {
  INC_THING
} from "../actionTypes"

const initialState = {
  thing: 0
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INC_THING:
      return { thing: state.thing + action.payload }
    default:
      return { ...state };
  }
}
