import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
// import 'bootstrap/dist/css/bootstrap.css';
import Loadable from 'react-loadable';
import ReactGA from 'react-ga';

import { Switch } from 'react-router-dom'
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from "react-redux";

import './index.scss';
import './styles/tailwind.css';

import Load from './components/Loading'
import { withTracker } from './components/withTracker';
import store from "./redux/store";

import App from './App';
// import Boards from './pages/boards/Boards';
import PrivateRoute from './auth/PrivateRoute';

const Loading = props => {
  if (props.error) {
    console.log(props)
  } else if (props.pastDelay) {
    return <Load loading={true} />;
  } else {
    return null;
  }
}
const defaultDelay = 500;

const Boards = Loadable({loader: () => import('./pages/boards/Boards'), delay: defaultDelay, loading: Loading });
const BoardNew = Loadable({loader: () => import('./pages/boards/new/BoardNew'), delay: defaultDelay, loading: Loading });
const BoardEdit = Loadable({loader: () => import('./pages/boards/edit/BoardEdit'), delay: defaultDelay, loading: Loading });

const Home = Loadable({loader: () => import('./pages/Home'), delay: defaultDelay, loading: Loading });
const Settings = Loadable({loader: () => import('./pages/settings/Settings'), delay: defaultDelay, loading: Loading });
const Billing = Loadable({loader: () => import('./pages/billing/Billing'), delay: defaultDelay, loading: Loading });

const Users = Loadable({loader: () => import('./pages/users/Users'), delay: defaultDelay, loading: Loading });
const Plans = Loadable({loader: () => import('./pages/plans/Plans'), delay: defaultDelay, loading: Loading });

const NotFound = Loadable({loader: () => import('./pages/404/NotFound'), delay: defaultDelay, loading: Loading });

const Login = Loadable({loader: () => import('./auth/Login'), delay: defaultDelay, loading: Loading });
const PasswordReset = Loadable({loader: () => import('./auth/PasswordReset'), delay: defaultDelay, loading: Loading });
const PasswordRecover = Loadable({loader: () => import('./auth/PasswordRecover'), delay: defaultDelay, loading: Loading });

const Signup = Loadable({loader: () => import('./pages/onboarding/Signup'), delay: defaultDelay, loading: Loading });
const ConfirmEmail = Loadable({loader: () => import('./pages/ConfirmEmail'), delay: defaultDelay, loading: Loading });

const Terms = Loadable({loader: () => import('./pages/Toc/Terms'), delay: defaultDelay, loading: Loading });
const FAQ = Loadable({loader: () => import('./pages/faq/FAQ'), delay: defaultDelay, loading: Loading });
const Docs = Loadable({loader: () => import('./pages/docs/Docs'), delay: defaultDelay, loading: Loading });

const GettingStarted = Loadable({loader: () => import('./pages/getting-started/Page'), delay: defaultDelay, loading: Loading });

const initGA = () => {
  const hn = window.location.hostname;
  if (hn === "localhost" || hn === "0.0.0.0") {
    window['ga-disable-UA-57285202-7'] = true
  }
  ReactGA.initialize('UA-57285202-7', {});
}

function WaitingComponent(Component) {
  return props => (
    <Suspense fallback={<div>WAITING Loading...</div>}>
      <Component {...props} />
    </Suspense>
  );
}

ReactDOM.render(
  <Provider store={store}>
    <App>
      <Router>
        {initGA()}
        <Switch>
          <Route exact path='/' component={withTracker(Home, {})}/>
          <Route exact path='/login' component={withTracker(Login, {})}/>
          <Route exact path='/signup' component={withTracker(Signup, {})}/>
          <Route exact path='/login/reset' component={withTracker(PasswordReset, {})}/>
          <Route exact path='/user/recover' component={withTracker(PasswordRecover, {})} />

          <Route exact path='/docs' component={withTracker(Docs, {})}/>

          <Route exact path='/terms-of-service' component={withTracker(Terms, {})}/>
          <Route exact path='/faq' component={withTracker(FAQ, {})}/>

          <Route exact path='/users/confirmation' component={withTracker(ConfirmEmail, {})}/>

          <PrivateRoute exact path='/users' component={withTracker(Users, {})}/>
          <PrivateRoute exact path='/plans' component={withTracker(Plans, {})}/>

          <PrivateRoute exact path='/boards' component={WaitingComponent(Boards)}/>
          <PrivateRoute exact path='/boards/new' component={withTracker(BoardNew, {})}/>
          <PrivateRoute exact path='/board/:id' component={withTracker(BoardEdit, {})}/>

          <PrivateRoute exact path='/billing' component={withTracker(Billing, {})}/>
          <PrivateRoute exact path='/settings' component={withTracker(Settings, {})}/>

          <Route exact path='/guides/getting-started' component={withTracker(GettingStarted, {})}/>

          <Route component={withTracker(NotFound, {})} />
        </Switch>
      </Router>
    </App>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
