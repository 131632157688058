import {
  SET_TOP_SCORES
} from "../actionTypes"

const initialState = {
  scores: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_TOP_SCORES:
      const data = action.payload
      return {
        scores: { [data.id]: data.data }
      }
    default:
      return {
        ...state
      };
  }
}
