import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API ? process.env.REACT_APP_API : 'http://0.0.0.0:1323',
  timeout: 10000,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
});

export const axiosAPI = (key) => {
  return axios.create({
    baseURL: process.env.REACT_APP_API ? process.env.REACT_APP_API : 'http://0.0.0.0:1323',
    timeout: 10000,
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-API-KEY': key,
    }
  });
}
