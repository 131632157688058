import React from 'react';
import { Route } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import BasicNavbar from '../navbar/BasicNavbar'

const PrivateRoute = ({ userStore, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!userStore.loaded) {
          return <BasicNavbar {...props} />
        } else if (userStore.authenticated) {
          return <Component {...props} />
        }
        return (
          userStore.loaded && <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }}
    />
  );
}

const mapStateToProps = state => {
  const userStore = state.user;
  return { userStore } ;
}

export default connect(mapStateToProps)(PrivateRoute);
