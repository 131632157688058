export const SET_USER = "SET_USER";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const PROFILE_REQUEST = "PROFILE_REQUEST";

export const GET_PAYMENT_PLANS = "GET_PAYMENT_PLANS";

export const GET_USERS = "GET_USERS";

export const GET_PLANS = "GET_PLANS";
export const SET_PLAN = "SET_PLAN";

export const SET_STRIPE_PLANS = "SET_STRIPE_PLANS";

export const INC_THING = "INC_THING";

export const SET_ONBOARDING_PLAN = "SET_ONBOARDING_PLAN";
export const SIGNUP_COMPLETE = "SIGNUP_COMPLETE";

export const ADD_BOARDS = "ADD_BOARDS";
export const UPDATE_BOARD = "UPDATE_BOARD";

export const SET_TOP_SCORES = "SET_TOP_SCORES";

export const SET_INVOICES = "SET_INVOICES";
export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";

export const SET_CREDS = "SET_CREDS";
