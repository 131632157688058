import {
  SET_CREDS,
} from "../actionTypes"

const initialState = {
  creds: {},
  loaded: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CREDS:
    return {
      ...state,
      loaded: true,
      creds: { ...action.payload }
    }
    default:
      return { ...state };
  }
}
