import {
  SET_ONBOARDING_PLAN,
  SIGNUP_COMPLETE
} from "../actionTypes"

const initialState = {
  plan: {},
  email: "",
  signupComplete: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ONBOARDING_PLAN:
      return {
        plan: { key: action.payload.key },
        email: action.payload.email || ""
      }
    case SIGNUP_COMPLETE:
      return { plan: {...state.plan}, signupComplete: true}
    default:
      return { ...state };
  }
}
